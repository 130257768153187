import React, { useState, useContext, useEffect } from 'react';
import { Card, CardHeader } from 'components/ui/card';
import { Button } from "components/ui/button";
import { DescriptionDetails, DescriptionList, DescriptionTerm } from 'components/catalyst/description-list';
import { Pencil, X } from "lucide-react";
import { Input } from "components/ui/input";
import { AppContext } from 'contexts/AppContext';

export default function ProfilePage() {
  const {  } = useContext(AppContext);

  return (
    <div className="border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <div className="flex flex-wrap pt-4">
        <Card className="w-full">
        <CardHeader>
          <div className="flex flex-row justify-between items-center">
            <h2 className="text-lg font-semibold">Profile</h2>
          </div>
          <DescriptionList>
          </DescriptionList>
        </CardHeader>
      </Card>
      </div>
    </div>
  );
}
