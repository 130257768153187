"use client";

import React, { useState, useEffect, useContext } from "react";import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";

import DataTablePagination from "./data-table-pagination";
import DataTableToolbar from "./data-table-toolbar";
import { AppContext } from "contexts/AppContext";
import { formatDate } from 'utils/helperFunctions'

function DataTable({ columns, data }) {
  const { dateRange, loading, setSelectedUser, onRowClick } = useContext(AppContext);

  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [sorting, setSorting] = React.useState([]);
  const [loadingText, setLoadingText] = useState("Finding your records");

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      globalFilter,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  // Update loading text periodically
  useEffect(() => {
    if (loading) {
      const texts = [
        "Finding your records",
        `Pulling data for dates ${formatDate(dateRange[0])} and ${formatDate(dateRange[1])}`,
        "Loading records",
      ];
      let index = 0;

      const interval = setInterval(() => {
        if (index < texts.length - 1) {
          setLoadingText(texts[index]);
          index++;
        } else {
          clearInterval(interval); // Stop updating after the last message
        }
      }, 2000); // Change text every 2 seconds

      return () => clearInterval(interval); // Cleanup interval
    }
  }, [loading, dateRange]);

  return (
    <div className="space-y-4">
      <DataTableToolbar table={table} />
      <div className="rounded-md border relative">
        {loading && (
          <div className="absolute inset-0 flex flex-col items-center justify-center bg-white bg-opacity-80 z-10" style={{ height: "300px" }}>
            <div className="loader animate-spin border-4 border-blue-500 border-t-transparent rounded-full h-12 w-12"></div>
            <p className="text-md font-medium">{loadingText}</p>
          </div>
        )}
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {!loading && table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onClick={() => onRowClick(row.original.user_id)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              !loading && (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results. Try inputting different dates.
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </div>
      {!loading && table.getRowModel().rows?.length && 
        <DataTablePagination table={table} />
      }
    </div>
  );
}

export default DataTable;
