import React from 'react';
import ReactDOM from 'react-dom/client';
import { AppProvider } from 'contexts/AppContext';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'next-themes';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="instalily.us.auth0.com"
      clientId="j5OMoC5Za1fOOatuFkmYnwCLLOAC8noS"
      cacheLocation={"localstorage"}
      authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://instalily.us.auth0.com/api/v2/"
      }}>
      <ThemeProvider attribute="class" defaultTheme="light" enableSystem>
        <BrowserRouter>
          <AppProvider>
            <App />
          </AppProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Auth0Provider>
  </React.StrictMode>
);
