"use client"

import { StackedLayout } from "components/catalyst/stacked-layout";
import { Navbar, NavbarDivider, NavbarItem, NavbarLabel, NavbarSection, NavbarSpacer } from 'components/catalyst/navbar'
import { Avatar } from 'components/catalyst/avatar'
import {Dropdown,DropdownButton,DropdownDivider,DropdownItem,DropdownLabel,DropdownMenu,} from 'components/catalyst/dropdown'
import { ChevronDown } from 'lucide-react'
import React, { useState, useContext } from 'react';
import { AppProvider } from "contexts/AppContext";
import { AppContext } from 'contexts/AppContext';

export function ClientDropdownMenu() {
    const {clientOptions,setSelectedClient, selectedClient } = useContext(AppContext)

    const handleSelectClient = (client) => {
        setSelectedClient(client);
      };

    return (
        <>
        {clientOptions.length === 1 ? (
            // Render selectedClient directly if only one option exists
            <div className="flex items-center space-x-2">
              <img
                src={selectedClient?.imageUrl || ""}
                alt={selectedClient?.label || ""}
                className="h-5 object-cover"
              />
              <NavbarLabel>{selectedClient?.label}</NavbarLabel>
            </div>
          ) : (
            // Render dropdown for multiple clientOptions
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <img
                  src={selectedClient?.imageUrl || ""}
                  alt={selectedClient?.label || ""}
                  className="h-5 object-cover"
                />
                <NavbarLabel>{selectedClient?.label}</NavbarLabel>
                <ChevronDown className="h-4 w-4" />
              </DropdownButton>
              <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
                {clientOptions.map((client, index) => (
                  <React.Fragment key={client.label}>
                    <DropdownItem onClick={() => handleSelectClient(client)}>
                      <img
                        src={client.imageUrl}
                        alt={client.label}
                        className="h-5 object-cover mr-2"
                      />
                      <DropdownLabel>{client.label}</DropdownLabel>
                    </DropdownItem>
                    {index < clientOptions.length - 1 && <DropdownDivider />}
                  </React.Fragment>
                ))}
              </DropdownMenu>
            </Dropdown>
          )}    
        </>      
    )
}
