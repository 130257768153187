"use client"

import { StackedLayout } from "components/catalyst/stacked-layout";
import { 
  Navbar, 
  NavbarDivider, 
  NavbarItem, 
  NavbarLabel, 
  NavbarSection, 
  NavbarSpacer 
} from 'components/catalyst/navbar'
import { 
  Sidebar, 
  SidebarBody, 
  SidebarHeader, 
  SidebarItem, 
  SidebarLabel, 
  SidebarSection,
  SidebarDivider,
  SidebarFooter
} from 'components/catalyst/sidebar'
import { Avatar } from 'components/catalyst/avatar'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from 'components/catalyst/dropdown'
import {
  ChevronDown,
  Settings,
  Plus,
  User,
  Shield,
  Lightbulb,
  LogOut,
  Search,
  Inbox,
  Settings2Icon,
  SettingsIcon,
  StarIcon
} from 'lucide-react'
import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import { useState, useContext } from 'react';
import { AppProvider } from "contexts/AppContext";
import { AppContext } from 'contexts/AppContext';
import logo_mini from 'img/logo_mini.png'
import { Link, useLocation } from 'react-router-dom';
import { ClientDropdownMenu } from './ClientDropdown'
import { SettingsDropdownMenu } from './SettingsDropdown'

export default function RootLayout({ children }) {

  return (
    <html className="bg-white lg:bg-zinc-100 dark:bg-zinc-900 dark:lg:bg-zinc-950">
      <body>
        <RootContent children={children}/>
      </body>
    </html>
  );
}

function RootContent({ children }) {

  const navItems = [
    { label: 'Home', url: '/' },
    { label: 'Monitoring', url: '/monitoring' },
  ]
  
  return (
    <StackedLayout
    navbar={
      <Navbar>
        <img src={logo_mini} className="w-5 h-5 ml-5"/>
        <NavbarLabel>Instalily Console</NavbarLabel>
        <NavbarDivider className="max-lg:hidden" />
        <NavbarSection className="max-lg:hidden">
            {navItems.map(({ label, url }) => (
              <NavbarItem key={label}>
                <Link to={url} className="text-inherit no-underline">
                  {label}
                </Link>
              </NavbarItem>
            ))}
          </NavbarSection>
        <NavbarSpacer />
        <NavbarSection className="max-lg:hidden">
            <ClientDropdownMenu />
        </NavbarSection>
        <SettingsDropdownMenu/>
      </Navbar>
    }
    sidebar={
      <Sidebar>
        <SidebarBody>
            <SidebarSection>
              {navItems.map(({ label, url }) => (
                <SidebarItem key={label}>
                  <Link to={url} className="text-inherit no-underline">
                    {label}
                  </Link>
                </SidebarItem>
              ))}
            </SidebarSection>
            <SidebarDivider/>
            <ClientDropdownMenu />
        </SidebarBody>
      </Sidebar>
    }
  >
    {children}
  </StackedLayout>
  );
}
