export const formatDecimal = (value) => {
  const numberValue = typeof value === "string" ? parseFloat(value) : value;

  if (typeof numberValue === "number" && !isNaN(numberValue)) {
    return numberValue.toFixed(3); 
  }
  return value;
};

export const formatValue = (value) => {
    if (value === null || value === undefined || value === '') {
      return '\u00A0'; 
    } else if (value >= 1000 && value < 1000000) {
      return `$${(value / 1000).toFixed(1)}K`;
    } else if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    } else {
      return `$${Math.floor(value).toLocaleString()}`;
    }
};

export const formatDate = (date) => {
  if (!date) return "";
  const parsedDate = new Date(date);
  if (isNaN(parsedDate)) return date; // Return original value if parsing fails
  return parsedDate.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
};

export const formatDateTime = (date) => {
  if (!date) return "";
  const parsedDate = new Date(date);
  if (isNaN(parsedDate)) return date; // Return original value if parsing fails

  // Format both date and time
  return parsedDate.toLocaleString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true, // Ensures AM/PM format
  });
};
