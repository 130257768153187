import React, { useContext } from "react";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import DataTableViewOptions from "./data-table-view-options";
import { ListRestart, DownloadIcon, XCircle } from "lucide-react";
import { AppContext } from "contexts/AppContext";

function formatDate(date) {
  return date instanceof Date && !isNaN(date.getTime())
    ? date.toISOString().split("T")[0]
    : ""; // Return an empty string for invalid dates
}

function DataTableToolbar({ table }) {
  const { dateRange, setDateRange, selectedUser, setSelectedUser } = useContext(AppContext);

  const isFiltered = table.getState().columnFilters.length > 0;

  const handleSearchChange = (e) => {
    const newSearch = e.target.value;
    table.setGlobalFilter(newSearch);
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    
    // Check if the value is a valid date
    const parsedDate = new Date(newStartDate);
    if (isNaN(parsedDate.getTime())) {
      console.error("Invalid start date:", newStartDate);
      setDateRange([null,dateRange[1]]);
      return; // Exit the function if the date is invalid
    }
  
    setDateRange([parsedDate, dateRange[1]]);
    table.setColumnFilters([
      {
        id: "date",
        value: { startDate: newStartDate, endDate: dateRange[1] ? formatDate(dateRange[1]) : null },
      },
    ]);
  };
  
  const handleEndDateChange = (e) => {
    if (!e) return
    const newEndDate = e.target.value;

    const parsedDate = new Date(newEndDate);
    if (isNaN(parsedDate.getTime())) {
      console.error("Invalid end date:", newEndDate);
      etDateRange([dateRange[0], null]);
      return; // Exit the function if the date is invalid
    }

    setDateRange([dateRange[0], new Date(newEndDate)]);
    table.setColumnFilters([
      { id: "date", value: { startDate: dateRange[0] ? formatDate(dateRange[0]) : null, endDate: newEndDate } }, // Replace "date" with your column ID
    ]);
  };

  const resetTable = () => {
    table.resetSorting();
    table.resetColumnFilters();
    table.setGlobalFilter("");
    table.resetRowSelection();
    table.resetColumnVisibility();
    setSelectedUser(null)
    setDateRange([
      new Date(new Date().setDate(new Date().getDate() - 29)), // 30 days ago
      new Date() // Today
    ]);
  };  

  const handleClearUserFilter = () => {
    setSelectedUser(null); // Clear the selected user when clicking the "X"
  };

  return (
    <div className="flex flex-col lg:flex-row items-center justify-between space-y-2 lg:space-y-0 lg:space-x-2">
      <div className="flex flex-col lg:flex-row flex-1 items-center space-y-2 lg:space-y-0 lg:space-x-2 w-full">
        <Input
          placeholder={`Search ${table.getFilteredRowModel().rows.length} Rows...`}
          value={table.getState().globalFilter || ""}
          onChange={handleSearchChange}
          className="h-8 w-full lg:w-[200px]"
        />
        <Input
          type="date"
          value={dateRange[0] ? formatDate(dateRange[0]) : ""}
          onChange={handleStartDateChange}
          placeholder="Start Date"
          className="h-8 w-full lg:w-[150px]"
        />
        <Input
          type="date"
          value={dateRange[1] ? formatDate(dateRange[1]) : ""}
          onChange={handleEndDateChange}
          placeholder="End Date"
          className="h-8 w-full lg:w-[150px]"
        />
      </div>
      {/* <DataTableViewOptions table={table} /> */}

      {selectedUser && (
        <div className="flex items-center space-x-2 ml-auto">
          <span className="bg-blue-700 text-white h-8 text-xs px-2 py-1 rounded-full flex items-center">
            {selectedUser || "User Selected"}
            <button onClick={handleClearUserFilter} className="ml-2 text-white">
              <XCircle className="h-4 w-4" />
            </button>
          </span>
        </div>
      )}

      <Button
        variant="outline"
        onClick={() => resetTable()}
        className="h-8 px-2 lg:px-3 w-full lg:w-auto ml-auto"
      >
        <ListRestart className="mr-2 h-4 w-4" />
        Reset Filters
      </Button>
      <Button variant="outline" className="h-8 px-2 lg:px-3 w-full lg:w-auto ml-auto">
        <DownloadIcon className="h-4 w-4" />
      </Button>
    </div>
  );
}

export default DataTableToolbar;
