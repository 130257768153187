import React, { useState, useContext, useEffect } from 'react';
import { Button } from "components/ui/button"
import { CalendarIcon, DownloadIcon, ChevronLeftIcon, ChevronRightIcon, ChevronsLeftIcon, ChevronsRightIcon } from 'lucide-react'
import { AppContext } from 'contexts/AppContext';
import { Divider } from 'components/catalyst/divider'

import DataTable from './table/data-table'
import DataTableWrapper from './table/data-table-wrapper'

export default function MonitoringPage() {
  const { messagesData, selectedClient, userData, activeTab, setActiveTab } = useContext(AppContext)

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (!selectedClient) return; 

    const fetchColumns = async () => {
      try {
        const { messages, users } = await import(`./columns/${selectedClient.key}.js`);
        if (activeTab=='Messages') { setColumns(messages)}
        if (activeTab=='Users') { setColumns(users)}
      } catch (error) {
        console.error(`Error loading columns for client: ${selectedClient}`, error);
        setColumns([]); 
      }
    };

    fetchColumns();
  }, [activeTab, selectedClient]);

  return (
    <div className="w-full border-b border-zinc-950/10 pb-6 dark:border-white/10 pt-4">
      <div className="mb-4">
        <h2 className="text-xl font-bold">Chat Agent Logs</h2>
        <p className="text-gray-600 mt-2">
          Chat agent logs for {selectedClient?.label}
        </p>      
    </div>
    <div className="flex space-x-4 mb-4">
      {/* Highlight the active tab and handle click */}
      <Button variant={activeTab === "Users" ? "default" : "outline"} onClick={() => setActiveTab("Users")}>
        Users {userData.length || ''}
      </Button>
      <Button variant={activeTab === "Messages" ? "default" : "outline"} onClick={() => setActiveTab("Messages")}>
        Messages {messagesData.length || ''}
      </Button>
    </div>
    <DataTable data={activeTab === "Messages" ? messagesData : userData} columns={columns || []}/>
    </div>
  );
}