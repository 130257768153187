"use client"

import { 
  Navbar, 
  NavbarDivider, 
  NavbarItem, 
  NavbarLabel, 
  NavbarSection, 
  NavbarSpacer 
} from 'components/catalyst/navbar'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownHeader,
  DropdownMenu,
} from 'components/catalyst/dropdown'
import {
  Settings,
  LogOut,
} from 'lucide-react'
import { useAuth0 } from '@auth0/auth0-react';

export function SettingsDropdownMenu({ children }) {

    const { logout, user } = useAuth0();
  
    return (
        <Dropdown>
            <DropdownButton as={NavbarItem}>
            <Settings className="h-5 w-5" />
            </DropdownButton>
            <DropdownMenu className="min-w-64" anchor="bottom end">
            {/* <DropdownItem>
                <Link to="/feedback" className="flex items-center text-inherit no-underline">
                <Lightbulb className="h-4 w-4" />
                <DropdownLabel className="ml-2">Share feedback</DropdownLabel>
                </Link>
            </DropdownItem> */}
            {/* <DropdownDivider /> */}
            <DropdownHeader>
                <span className="font-semibold">{user?.email}</span>
            </DropdownHeader>
            <DropdownDivider />
            <DropdownItem onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                <div className="flex items-center text-inherit no-underline cursor-pointer">
                    <LogOut className="h-4 w-4" />
                    <span className="ml-2">Sign out</span>
                </div>
            </DropdownItem>
            </DropdownMenu>
        </Dropdown>
  );
}
