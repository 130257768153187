import React, { useContext } from 'react';
import './globals.css';  
import { SidebarLayout } from 'components/catalyst/sidebar-layout';
import { Toaster } from "components/ui/sonner"
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import Header from 'components/layout/Header'

import MonitoringPage from 'pages/monitoring/Index';
import FeedbackPage from 'pages/feedback/Index';
import ProfilePage from 'pages/profile/Index';
import PolicyPage from 'pages/privacy-policy/Index';
import AuthGuard from 'utils/AuthGuard'

function App() {

  return (
        <Header children={
          <>
          <Routes>
            <Route
              path="*"
              element={
                <AuthGuard>
                  <Routes>
                    <Route path="/" element={<MonitoringPage />} />
                    <Route path="/monitoring" element={<MonitoringPage />} />
                    <Route path="/profile" element={<ProfilePage />} />
                    {/* <Route path="/privacy-policy" element={<PolicyPage />} /> */}
                    <Route path="/feedback" element={<FeedbackPage />} />
                  </Routes>
                </AuthGuard>
              }
            />
          </Routes>
          <Toaster />
          </>
        }/>
  );
}

export default App;